<template>
  <div class="return-goods">
    <div class="title">退款政策</div>
    <el-divider />
    <div class="content">
      我們承諾<br /><br />

      珍珠首飾的款式及造工都保證如相中所顯示的，唯獨珍珠是天然的有機寶石，其光澤度、 圓度及瑕疵度都是獨一無二、不可複製的。<br /><br />

      每件出產的珍珠首飾都會精挑細選最優質上乘的珍珠，盡量把守：珍珠的正面無瑕、珠光明亮有光澤、珠形圓潤飽滿等三大原則，將珍珠最美好的一面呈獻給您。<br /><br /><br /><br /><br />



      關於退款及換貨<br />
      （全球地區適用)<br /><br />

      服務只適用於網上商店購物使用。<br />
      在收到商品起計的24小時內，客戶可於試戴後決定是否需要退款。<br />
      如需退款，客戶請於24小時內（以取件時間作準），安排以 "運費寄付" 方式將商品寄回到本店並聯絡我們安排退款（運費必需由閣下辦理，否則本店將有權不收取包裹 及 安排退款）。<br />
      如在取件後超過了24小時，客戶等同接受並確認了是次交易的商品，款項恕不獲退還。<br />
      收到退貨包裹後，我們會隨即作出登記。待處理退貨完畢，您便會收到通知。退款過程則視乎您的財務機構的指引，可能需時達7-14個工作天，才可將退款按照訂購時所採用的相同付款方式入數。從郵寄日期起計，整個退貨和退款程序可能需時達1-3周。 <br />
      如產品有任何損壞 或 遺失，本店有權拒絕退款。<br />
      如有任何爭議，本公司保留是否退款的最終決定權。<br /><br /><br /><br /><br />


      
      關於損毀<br /><br />

      如產品在送抵時有任何損壞，請於貨物送抵後7天內通知我們產品損壞狀態，並附上照片，以便商討有關換貨安排。 <br /><br />

      請注意所有包裝必須完整保留並出示購貨之單據，否則我們有權拒絕換貨。於檢查後，我們會為閣下安排更換同款商品（退款及更換成其他商品將不予接受）。對於不符合要求的 商品，我們將不予接受更換，並會原樣寄回給顧客。<br /><br />

      如有任何爭議，ChunLin保留一切修訂條款及細則之權利，毋須另行通知。
    </div>
  </div>
</template>

<style lang="less" scoped>
.return-goods {
  padding: 0 11.5%;

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    margin: 33px 0 21px;
  }

  .content {
    font-size: 16px;
    font-weight: 400;
    color: #3D3D3D;
    line-height: 24px;
  }
}
</style>
